<app-header></app-header>
<app-banner></app-banner>
<app-block-box></app-block-box>
<app-about-text></app-about-text>
<app-page-cta></app-page-cta>
<app-donation></app-donation>
<app-broadcast></app-broadcast>
<app-ministry></app-ministry>
<app-volunteers></app-volunteers>
<app-testimonials></app-testimonials>
<app-blogs></app-blogs>
<footer class="sigma_footer footer-2 sigma_footer-dark">
    <app-footer></app-footer>
</footer>