<!-- About Start -->
<section class="section section-md light-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-30">
                <div class="section-title section-title-2 text-start">
                    <p class="subtitle">Nós do Amal</p>
                    <h4 class="title">Diversão, Amizade e Filantropia</h4>
                    <p>Junte-se a uma rede de homens com ideias semelhantes e divirta-se enquanto apoia o Shriners Children's e faz amigos para toda a vida.</p>
                </div>
                <div class="d-flex align-items-center mt-5">
                    <div class="sigma_round-button me-4 sm">
                        <span> <b class="counter" [countUp]="1">0</b> <span class="custom-primary">M+</span> </span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enable-background="new 0 0 197 197" xml:space="preserve">
                            <circle class="sigma_round-button-stroke" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                            <circle [style.stroke-dashoffset.px]="290" class="sigma_progress-round sigma_round-button-circle" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                        </svg>
                    </div>
                    <div>
                        <h5 class="mb-2">Crianças Atendidas</h5>
                        <p class="mb-0">Mais de 1.500.000 (um milhão e quinhentas mil) crianças atendidas</p>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-5">
                    <div class="sigma_round-button me-4 sm">
                        <span> <b class="counter" [countUp]="750">0</b> <span class="custom-secondary">+</span> </span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enable-background="new 0 0 197 197" xml:space="preserve">
                            <circle class="sigma_round-button-stroke" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                            <circle [style.stroke-dashoffset.px]="400" class="sigma_progress-round sigma_round-button-circle secondary" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                        </svg>
                    </div>
                    <div>
                        <h5 class="mb-2">Membros Ativos</h5>
                        <p class="mb-0">Já somos mais de 750 Nobres Irmãos que trabalham, se divertem e fazem amizade no Amal Shriners Brasil</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 d-none d-lg-block">
                <div class="me-lg-30 img-group-2">
                    <img src="assets/img/about2.jpeg" alt="Filantropia">
                    <img src="assets/img/about1.jpeg" alt="Diversão">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About End -->