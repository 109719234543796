import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgxMaskModule} from 'ngx-mask';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PastPotentatesComponent } from './components/pages/past-potentates/past-potentates.component';
import {HomeThreeModule} from './components/pages/home-three/home-three.module';
import {SharedModule} from './components/shared/shared.module';
import {HomeTwoModule} from './components/pages/home-two/home-two.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {CustomDatepickerI18n, I18n} from './helpers/datepickers/CustomDatepickerI18n';
import {NgbDatePTParserFormatter} from './helpers/datepickers/NgbDatePTParserFormatter';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    PastPotentatesComponent
  ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BreadcrumbModule,
        NgbModule,
        HttpClientModule,
        SweetAlert2Module.forRoot(),
        NgxMaskModule.forRoot(),
        NgxUiLoaderModule,
        HomeThreeModule,
        SharedModule,
        HomeTwoModule,
        NgxPaginationModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
  providers: [
      [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
      [{provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter}],{
          provide: MAT_DATE_LOCALE, useValue: 'pt_BR'
      },{
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
