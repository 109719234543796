<div class="section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <a routerLink="/sermons" class="sigma_service border style-1 bg-white">
                    <div class="sigma_service-thumb">
                        <i class="flaticon-church"></i>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="sigma_service-body">
                        <h5>Our Church</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod </p>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6">
                <a routerLink="/ministries" class="sigma_service border style-1 primary-bg">
                    <div class="sigma_service-thumb">
                        <i class="text-white flaticon-speech"></i>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="sigma_service-body">
                        <h5 class="text-white">Ministries</h5>
                        <p class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod </p>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-6">
                <a routerLink="/events" class="sigma_service border style-1 secondary-bg">
                    <div class="sigma_service-thumb">
                        <i class="custom-primary flaticon-praying"></i>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="sigma_service-body">
                        <h5 class="text-white">Events</h5>
                        <p class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>