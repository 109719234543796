<!-- Donation Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="section-title text-center">
            <p class="subtitle">Escolha uma campanha para doar</p>
            <h4 class="title">Ajude a causa Shriner</h4>
        </div>
        <div class="row">
            <!-- Data -->
            <div class="col-lg-4 col-md-6" *ngFor="let item of campaings | slice:0:3">
                <div class="sigma_service style-2">
                    <div class="sigma_service-thumb">
                        <img [src]="'https://cdn.amalshriners.org.br/campaings/' + item.uuid + '/' + item.image" [alt]="item.shorttext">
                    </div>
                    <div class="sigma_service-body">
                        <h5>
                            <a routerLink="/donation/{{item.uuid}}">{{item.campaing}}</a>
                        </h5>
                        <p>{{item.shorttext}}</p>
                        <div class="sigma_service-progress">
                            <div class="progress-content">
                                <p>Arrecadado: R${{item.raised ? item.raised : 0  | number:'1.0-0'}}</p>
                                <p>Meta: R${{item.goal | number:'1.0-0'}}</p>
                            </div>
                            <div class="sigma_progress">
                                <div class="progress">
                                    <div class="progress-bar"  [style.width.%]="item.raised ? item.raised : 0 * 100 / item.goal">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a routerLink="/donation/{{item.uuid}}" class="sigma_btn-custom">
                            {{item.buttonText}}
                        </a>
                    </div>
                </div>
            </div>
            <!-- Data -->
        </div>
    </div>
</div>
<!-- Donation End -->
