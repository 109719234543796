<!-- partial:partia/__sidenav.html -->
<aside class="sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg"
    [ngClass]="canvasMethod ? 'open':''">
    <app-canvas></app-canvas>
</aside>
<div class="sigma_aside-overlay aside-trigger-right" (click)="toggleCanvas()"></div>
<!-- partial -->
<!-- partial:partia/__mobile-nav.html -->
<aside class="sigma_aside sigma_aside-left" [ngClass]="navMethod ? 'open':''">
    <app-mobile-menu></app-mobile-menu>
</aside>
<div class="sigma_aside-overlay aside-trigger-left" (click)="toggleNav()"></div>
<!-- partial -->
<!-- partial:partia/__header.html -->
<header class="sigma_header header-3 can-sticky header-absolute">
    <!-- Middle Header Start -->
    <div class="sigma_header-middle">
        <nav class="navbar">
            <!-- Logo Start -->
            <div class="sigma_logo-wrapper">
                <a routerLink="/" class="navbar-brand">
                    <img src="assets/img/amal-logo.png" alt="logo" style="height: 100px" class="center">
                </a>
            </div>
            <!-- Logo End -->
            <!-- Menu -->
            <div class="sigma_header-inner">
                <div class="sigma_header-top">
                    <div class="sigma_header-top-inner">
                        <ul class="sigma_header-top-links">
                            <li class="menu-item"> <a href="https://wa.me/5511951340724"> <i class="fab fa-whatsapp"></i> +55 11 95134-0724</a> </li>
                            <li class="menu-item"> <a href="mailto:contato@amalshriners.org"> <i class="fal fa-envelope"></i> contato@amalshriners.org</a> </li>

                        </ul>
                        <ul class="navbar-nav">
                            <!-- Data -->
                            <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                                *ngFor="let item of navigationtwo">
                                <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}</a>
                                <a routerLink="{{item.link}}" *ngIf="!item.child && !item.linkExternal ">{{item.linkText}}</a>
                                <a href="{{item.link}}" *ngIf="!item.child && item.linkExternal ">{{item.linkText}}</a>
                                <ul class="sub-menu" *ngIf="item.child">
                                    <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                                        *ngFor="let item of item.submenu">
                                        <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}</a>
                                        <a routerLink="{{item.link}}" *ngIf="!item.child && !item.linkExternal ">{{item.linkText}}</a>
                                        <a href="{{item.link}}" *ngIf="!item.child && item.linkExternal ">{{item.linkText}}</a>
                                        <ul class="sub-menu" *ngIf="item.child">
                                            <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                                                *ngFor="let item of item.submenu">
                                                <a routerLink="{{item.link}}">{{item.linkText}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <!-- Data -->
                        </ul>
                        <ul class="sigma_header-top-links">
                            <li class="d-flex align-items-center">
                                <a href="https://store.amalshriners.org.br" target="_blank" class="live">
                                    <i class="fa fa-circle"></i>
                                    Lojinha
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex justify-content-center justify-content-lg-between">
                    <ul class="navbar-nav">
                       <!-- Data -->
                    <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                    *ngFor="let item of navigation">
                    <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}</a>
                        <a routerLink="{{item.link}}" *ngIf="!item.child && !item.linkExternal ">{{item.linkText}}</a>
                        <a href="{{item.link}}" *ngIf="!item.child && item.linkExternal ">{{item.linkText}}</a>
                    <ul class="sub-menu" *ngIf="item.child">
                        <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                            *ngFor="let item of item.submenu">
                            <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}</a>
                            <a routerLink="{{item.link}}" *ngIf="!item.child && !item.linkExternal ">{{item.linkText}}</a>
                            <a href="{{item.link}}" *ngIf="!item.child && item.linkExternal ">{{item.linkText}}</a>
                            <ul class="sub-menu" *ngIf="item.child">
                                <li [ngClass]="item.child ? 'menu-item menu-item-has-children':'menu-item'"
                                    *ngFor="let item of item.submenu">
                                    <a routerLink="{{item.link}}" *ngIf="!item.linkExternal ">{{item.linkText}}</a>
                                    <a href="{{item.link}}" *ngIf="item.linkExternal ">{{item.linkText}}</a>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </li>

                <!-- Data -->
                    </ul>
                    <div class="sigma_header-controls style-2 p-0 border-0">
                        <ul class="sigma_header-controls-inner pe-3">
                            <li class="style-2">
                                <a href="https://app.amalshriners.org.br" target="_blank" class="d-none d-sm-flex align-items-center pe-0" title="Acesse o Sistema">
                                    <i class="fa fa-user"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Controls -->
            <div class="sigma_header-controls style-2">
                <a routerLink="/donation" class="sigma_btn-custom"> Faça sua Doação</a>
                <ul class="sigma_header-controls-inner">
                    <!-- Mobile Toggler -->
                    <li class="aside-toggler style-2 aside-trigger-left" (click)="toggleNav()">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <!-- Middle Header End -->
</header>
<!-- partial -->
