<!-- Testimonials Start -->
<section class="section pt-0">
    <div class="container testimonial-section bg-contain bg-norepeat bg-center" [style.background-image]="'url(assets/img/textures/map-2.png)'">
        <div class="section-title text-center">
            <p class="subtitle">Testimonials</p>
            <h4 class="title">What Our Congregation Say</h4>
        </div>
        <div class="sigma_testimonial style-2">
            <ngx-slick-carousel [config]="settings" class="sigma_testimonial-slider">
                <!-- Data -->
                <div class="sigma_testimonial-inner" ngxSlickItem *ngFor="let item of testimonials">
                    <div class="sigma_testimonial-thumb" *ngFor="let author of getAuthor(item.author)">
                        <img [src]="author.image" [alt]="author.name">
                    </div>
                    <div> 
                        <div class="sigma_testimonial-body">
                            <div class="sigma_rating-wrapper">
                                <div class="sigma_rating">
                                    <ngb-rating [max]="5" [(rate)]="item.rating" [readonly]="true">
                                        <ng-template let-fill="fill" let-index="index">
                                            <i class="fas fa-star" [class.active]="fill === 100"></i>
                                        </ng-template>
                                    </ngb-rating>
                                </div>
                            </div>
                            <p>{{item.comment}}</p>
                        </div>
                        <div class="sigma_testimonial-footer" *ngFor="let author of getAuthor(item.author)">
                            <div class="sigma_testimonial-author">
                                <cite>{{author.name}}</cite>
                                <span>{{author.post}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Data -->
            </ngx-slick-carousel>
        </div>
        <div class="text-center mt-4">
            <div class="sigma_arrows style-2">
                <i class="far fa-chevron-left slick-arrow slider-prev"></i>
                <i class="far fa-chevron-right slick-arrow slider-next"></i>
            </div>
        </div>
    </div>
</section>
<!-- Testimonials End -->