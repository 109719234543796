<!-- CTA Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row position-relative">
            <div class="col-lg-7 col-md-6">
                <div class="sigma_cta lg secondary-bg">
                    <img class="d-none d-lg-block" src="assets/img/cta/3.png" alt="cta">
                    <div class="sigma_cta-content">
                        <span class="fw-600 custom-primary">Need Help, Call Our HOTLINE!</span>
                        <h4 class="text-white">+1 212-683-9756</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 position-relative">
                <div class="sigma_cta sm primary-bg">
                    <span class="sigma_cta-sperator d-none d-lg-flex">or</span>
                    <div class="sigma_cta-content">
                        <form>
                            <label class="mb-0 text-white">Our Church Newsletter</label>
                            <div class="sigma_search-adv-input">
                                <input type="text" class="form-control" placeholder="Enter email address" name="search">
                                <button type="submit" name="button"><i class="far fa-envelope"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->