<!-- Testimonials Start -->
<section class="section sigma_testimonial-sec style-4">
    <div class="container testimonial-section style-4">
        <div class="row">
            <div class="col-lg-5">
                <div class="section-title text-start">
                    <p class="subtitle">Testimonials</p>
                    <h4 class="title">What Our Members Say</h4>
                    <p>We are a church that belives in Jesus christ and the followers and We are a church that belives in Jesus christ. This is where you should start Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod</p>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="sigma_testimonial style-4">
                    <div class="sigma_testimonial-body">
                        <div class="sigma_testimonial-image d-none d-lg-block">
                            <div class="row justify-content-center align-items-center g-0">
                                <div class="col-md-3">
                                    <img src="assets/img/testimonials/c-img01.png" alt="img">
                                </div>
                                <div class="col-md-5 ms-3">
                                    <img src="assets/img/testimonials/c-img04.png" alt="img">
                                </div>
                                <div class="col-md-3 ms-3">
                                    <img src="assets/img/testimonials/c-img03.png" alt="img">
                                </div>
                            </div>
                        </div>
                        <i class="flaticon-right-quote icon"></i>
                        <ngx-slick-carousel [config]="settings" class="sigma_testimonial-slider-1">
                            <!-- Data -->
                            <div class="sigma_testimonial-slider-item" ngxSlickItem *ngFor="let item of testimonials">
                                <p>{{item.comment}}</p>
                                <div class="sigma_testimonial-author" *ngFor="let author of getAuthor(item.author)">
                                    <cite>{{author.name}}</cite>
                                </div>
                            </div>
                            <!-- Data -->
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Testimonials End -->