<div class="section section-padding secondary-bg">
    <div class="container" *ngIf="events.length > 0">
        <div class="row sigma_sermon-box-wrapper">
            <div class="col-lg-6"  *ngFor="let item of events ">
                <div class="sigma_sermon-box">
                    <div class="sigma_box">
                        <span class="subtitle">Próximos Eventos</span>
                        <h4 class="title mb-0">
                            <a routerLink="/event-details/{{item.uuid}}">{{item.title}}</a>
                        </h4>
                        <div class="sigma_sermon-info">
                            <div class="sigma_sermon-date" [innerHtml]="getDateInitials(item.eventDate)"></div>
                            <ul class="sigma_sermon-descr m-0">
                                <li>
                                    <i class="far fa-clock"></i>
                                    <span [innerHTML]="getEventTimeDay(item.eventDate)"></span>
                                </li>
                                <li>
                                    <i class="far fa-map-marker-alt"></i>
                                    {{item.location}}
                                </li>
                            </ul>
                        </div>
                        <div class="section-button d-flex align-items-center">
                            <a routerLink="/event-details/{{item.uuid}}" class="sigma_btn-custom secondary">Inscreva-se <i class="far fa-arrow-right"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="events.length < 1">
        <div class="row sigma_sermon-box-wrapper">
            <div class="col-lg-12">
                <div class="sigma_sermon-box">
                    <div class="sigma_box">
                        <span class="subtitle">Sem novos eventos no momento</span>
                        <h4 class="title mb-0">
                            Volte em breve para ver novos eventos
                        </h4>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
