<div class="section section-padding light-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-5">
                <div class="section-title text-end">
                    <p class="subtitle">Outas formas de ajudar!</p>
                    <h4 class="title">Doe seu tempo ou suas habilidades!</h4>
                </div>
            </div>
            <div class="col-md-2 d-none d-md-block"> <span class="vertical-seperator"></span> </div>
            <div class="col-md-5 d-none d-md-block">
                <p class="fw-600 mb-0 custom-secondary">Existem várias formas de colaborar com a causa Shriner, você tambem pode doar o seu tempo, ou uma habilidade específica para colaborar:</p>
            </div>
        </div>
        <div class="row">
            <!-- Data -->
            <div class="col-lg-6 col-md-6">
                <a routerLink="/volunteer/habilities" class="sigma_service style-3">
                    <div class="sigma_service-thumb">
                        <img src="assets/img/talentos.jpg" [alt]="'doe o seu talento'">

                    </div>
                    <div class="sigma_service-body">
                        <h5>Suas Habilidades</h5>
                        <p>Você pode doar suas habilidades, como programadores, médicos, advogados, nutricionistas, fisioterapeutas e outros</p>
                    </div>
                </a>
            </div>
            <div class="col-lg-6 col-md-6">
                <a routerLink="/volunteer" class="sigma_service style-3">
                    <div class="sigma_service-thumb">
                        <img src="assets/img/voluntario.jpg" [alt]="'Seja um Voluntário'">

                    </div>
                    <div class="sigma_service-body">
                        <h5>Voluntariado</h5>
                        <p>Cadastre-se e seja um voluntário do Amal, você poderá ajudar em nossos eventos e no cuidado com nossas crianças</p>
                    </div>
                </a>
            </div>
            <!-- Data -->
        </div>
    </div>
</div>