import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SiteService} from '../../../../services/site.service';

@Component({
  selector: 'app-next-event',
  templateUrl: './next-event.component.html',
  styleUrls: ['./next-event.component.css']
})
export class NextEventComponent implements OnInit {
  events: any = [];
  constructor(private siteService: SiteService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getNextEvents();
  }

  changeToMonth(month: string | number | any): any {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return months[month];
  }
  changeToDay(day: string | number | any): any {
    const days = ['Domingo', 'Segunda', 'Terças', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
    return days[day];
  }

  // tslint:disable-next-line:typedef
  getDateInitials(dateEvent: string): string {
    const date = new Date(dateEvent);
    const month = this.changeToMonth(date.getMonth());
    const shortMont = month.substring(0, 3);
    return '<span>' + ('0' + date.getDate()).slice(-2) + '</span>' + shortMont + ' ' + date.getFullYear() ;
  }
  getEventTimeDay(dateEvent: string): string{

    const date = new Date(dateEvent);
    const weekDay = this.changeToDay(date.getDay());
    return weekDay + ' (' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ')';

  }

  getNextEvents(): any{
    console.log("CHAMEI");
    this.siteService.getNextEvents().subscribe(result => {
      this.events = result;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      return true;
    });
  }


}
