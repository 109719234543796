import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import data from "../../../data/bannerthree.json";
import {SiteService} from "../../../../services/site.service";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  public banner = data;
  constructor(private siteService: SiteService, private cdr: ChangeDetectorRef) { }
  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false
        }
      }
    ]
  }

  ngOnInit(): void {
    this.getBanners();
  }

  // tslint:disable-next-line:typedef
  getBanners(){
    this.siteService.getBanners().subscribe((result) => {
      this.banner = result;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
  }

}
