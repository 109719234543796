import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import data from "../../data/footerlinks.json";
import { BlogHelperService } from '../../helper/blog/blog-helper.service';
import {SiteService} from '../../../services/site.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public links = data;
  lastNews: any = [];

  constructor(private siteService: SiteService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.siteService.getLastNews().subscribe((result: any) => {
      this.lastNews = result;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
  }
}
