import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { DonationHelperService } from 'src/app/components/helper/donation/donation-helper.service';
import {SiteService} from '../../../../services/site.service';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.css']
})
export class DonationComponent  implements OnInit {
  campaings: any;

  constructor(private siteService: SiteService,
              private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): any{
    this.getHomeDonations();
  }

  getHomeDonations(): any {
    this.siteService.getHomeCampaings().subscribe((result: any) => {
      this.campaings = result;
      this.cdr.detectChanges();
    });

    //https://cdn.amalshriners.org.br/campaings/df8bd00e-4359-45b9-8c34-22e4f0676e79/mcdiafeliz.jpg
  }



}
