import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { }

  getBanners() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/banners`, { headers: httpHeaders });
  }

  getDivan() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/divan`, { headers: httpHeaders });
  }
  getOldDivan() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/divan/old`, { headers: httpHeaders });
  }
  getDivanMember(uuid: any) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/divan/member/${uuid}`, { headers: httpHeaders });
  }

  getNextEvents() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/next-events`, { headers: httpHeaders });
  }
  getEvents() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/events`, { headers: httpHeaders });
  }

  getEvent(uuid:any) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/event/${uuid}`, { headers: httpHeaders });
  }
  getHomeCampaings(){
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/campaings/home`, { headers: httpHeaders });
  }

  // tslint:disable-next-line:typedef
  getPaymentSecret(data: any){
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.api}/site//donation/payment-intent`, data, { headers: httpHeaders });
  }

  public getClubes() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/clubs`, { headers: httpHeaders });
  }


  public getClub(uuid: any) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/club/${uuid}`, { headers: httpHeaders });
  }

  // tslint:disable-next-line:typedef
  sendDonation(body: any){
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.api}/site/donation`, body,{ headers: httpHeaders });
  }

  // tslint:disable-next-line:typedef
  getLastNews(){
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/site/last-news`, { headers: httpHeaders });
  }
  createPatient(body: any) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.api}/site/patient`, body, { headers: httpHeaders });
  }




}
