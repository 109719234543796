import { Injectable, AfterContentInit, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import volunteer from '../../data/volunteer/volunteer.json';
import {SiteService} from "../../../services/site.service";

@Injectable({
  providedIn: 'root'
})
export class VolunteerHelperService implements AfterContentInit {
  // pagination
  page = 1;
  // Volunteer
  public volunteerblock = volunteer;
  public volunteerdetails = volunteer;
  public volunteerOldBlock = volunteer;
  constructor(private route: ActivatedRoute, private siteService: SiteService) {
    console.log('init');
    this.getDivan();
    this.getOldDivan();
  }

  // Show Social
  public visible = false;
  // tslint:disable-next-line:typedef
  socialTrigger(item: { visible: boolean; }){
    item.visible = !item.visible;
  }
  // Volunteer Details
  // tslint:disable-next-line:typedef
  public getVolunteer(id: any) {
    this.siteService.getDivanMember(id).subscribe((result) => {
      this.volunteerdetails = result;
    });
  }
  // tslint:disable-next-line:contextual-lifecycle
  ngAfterContentInit(): void {
    this.getVolunteer(this.route.snapshot.params.id);
  }
  // tslint:disable-next-line:typedef
  getDivan(){
    this.siteService.getDivan().subscribe((result) => {
      this.volunteerblock = result;
    });
  }

  getOldDivan() {
    this.siteService.getOldDivan().subscribe((result) => {
      this.volunteerOldBlock = result;
    });
  }

}
