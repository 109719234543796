import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {environment} from '../../../../../environments/environment';
import {SiteService} from '../../../../services/site.service';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CepService} from '../../../../services/cep.service';
import data from '../../../data/faqs.json';
// @ts-ignore
declare var EfiJs: any;

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  @ViewChild('modalPix', {static: true}) modalPix: ElementRef | undefined;
  @ViewChild('modalBoleto', {static: true}) modalBoleto: ElementRef | undefined;
  @ViewChild('modalCartaoApproved', {static: true}) modalCartaoApproved: ElementRef | undefined;
  @ViewChild('modalCartaoWaiting', {static: true}) modalCartaoWaiting: ElementRef | undefined;

  paying = false;

  qrCode: string | undefined;
  qrCodeText: string | undefined;

  linhaDigitavel: string | undefined;
  linkBoleto: string | undefined;

  brand: any;

// @ts-ignore
  stripeTest: FormGroup;
  estados: any;
  constructor(private fb: FormBuilder,
              private siteService: SiteService,
              private cdr: ChangeDetectorRef,
              private modal: NgbModal,
              private cepService: CepService,
              private sanitizer: DomSanitizer,
              private ngxLoader: NgxUiLoaderService) {

  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.ngxLoader.start();
    this.startForm();
    this.getEstados();
  }
  // tslint:disable-next-line:typedef
  startForm(){
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      taxNumber: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      donationType: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      cardToken: [''],
      amount: ['', [Validators.required]],
      creditCard: [],
      expiryMonth: [],
      expiryYear: [],
      cvv: [],
      cardBrand: [],
      acceptTerms: ['', [Validators.required]],
      anonymous: [false],
      communications: [false],
      nascimento: ['', Validators.required],
      zipcode: ['', Validators.required],
      address: ['', Validators.required],
      number: ['', Validators.required],
      complement: [''],
      neigthborhood: ['', Validators.required],
      city: ['', Validators.required],
      county: ['', Validators.required],
      includeFees: [false]
    });
  }

  // tslint:disable-next-line:typedef
  save() {
    if (this.stripeTest.valid){
    if (this.stripeTest.get('paymentMethod')?.value === 1){
      // tslint:disable-next-line:max-line-length
      this.ngxLoader.start();
      // tslint:disable-next-line:max-line-length
      this.tokenCard(this.stripeTest.get('cardBrand')?.value, this.stripeTest.get('creditCard')?.value, this.stripeTest.get('cvv')?.value, this.stripeTest.get('expiryMonth')?.value, this.stripeTest.get('expiryYear')?.value);
    }else{
      this.sendForm();
    }
    }else{
      Swal.fire('Erro!', 'Verifique o Formulário', 'error');
      console.log('erro formulario');
    }
  }

  // tslint:disable-next-line:typedef
  sendForm(){

    let confirmationText;
    let admFee;
    if (this.stripeTest.get('includeFees')?.value){
      if (this.stripeTest.get('paymentMethod')?.value === 1){
        // @ts-ignore
        admFee = parseFloat((parseFloat(this.stripeTest.get('amount')?.value).toFixed(2) / 100 ) * 5).toFixed(2);
        confirmationText = `Você esta doando R$ ${parseFloat(this.stripeTest.get('amount')?.value).toFixed(2)} ao Amal Shriners mais uma taxa administrativa de R$ ${admFee}`;
      }
      if (this.stripeTest.get('paymentMethod')?.value === 2){
        confirmationText = `Você esta doando R$ ${parseFloat(this.stripeTest.get('amount')?.value).toFixed(2)} ao Amal Shriners mais uma taxa administrativa de R$ 4.00`;
      }
      if (this.stripeTest.get('paymentMethod')?.value === 3){
        // @ts-ignore
        admFee = parseFloat((parseFloat(this.stripeTest.get('amount')?.value).toFixed(2) / 100 ) * 1.3).toFixed(2);
        confirmationText = `Você esta doando R$ ${parseFloat(this.stripeTest.get('amount')?.value).toFixed(2)} ao Amal Shriners mais uma taxa administrativa de R$ ${admFee}`;

      }
    }else{
      confirmationText = `Você esta doando ${parseFloat(this.stripeTest.get('amount')?.value).toFixed(2)} ao Amal Shriners`;
    }
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      icon: 'question',
      title: 'Confirmar Doação',
      text: confirmationText,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não, cancelar',
      showCancelButton: true,
    }).then(confirmation => {
      if (confirmation.isConfirmed) {
        this.ngxLoader.start();
        // @ts-ignore
        // this.blockUI.start('Aguarde enquanto processamos a sua doação');
        this.siteService.sendDonation(this.stripeTest.getRawValue()).subscribe((result: any) => {
          if (result.success){
            if (this.stripeTest.get('paymentMethod')?.value === 1){

              if (result.status === 'approved'){
                this.modal.open(this.modalCartaoApproved);
                this.stripeTest.reset();
                this.ngxLoader.stop();
              }
              if (result.status === 'waiting'){
                  this.modal.open(this.modalCartaoWaiting);
                  this.stripeTest.reset();
                  this.ngxLoader.stop();
              }

              if (result.status === ''){
                this.ngxLoader.stop();
              }


            }
            if (this.stripeTest.get('paymentMethod')?.value === 2){
              this.ngxLoader.stop();
              this.qrCodeText = result.cob.pix.qrcode;
              //@ts-ignore
              this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(result.cob.pix.qrcode_image);
              this.linhaDigitavel = result.cob.barcode;
              this.linkBoleto = result.cob.billet_link;
              this.modal.open(this.modalBoleto, {size: 'lg'});
              this.cdr.detectChanges();
              this.cdr.markForCheck();
              this.stripeTest.reset();
            }

            if (this.stripeTest.get('paymentMethod')?.value === 3){
              // @ts-ignore
              this.ngxLoader.stop();
              //@ts-ignore
              this.qrCode = this.sanitizer.bypassSecurityTrustResourceUrl(result.cob.QRCOde.imagemQrcode);
              this.qrCodeText = result.cob.QRCOde.qrcode;

              this.modal.open(this.modalPix);
              this.cdr.detectChanges();
              this.cdr.markForCheck();
              this.stripeTest.reset();
            }
          }else{
            // @ts-ignore
            this.ngxLoader.stop()
            Swal.fire('Erro!', result.message, 'error');
          }
          console.log(result);
        }, err => {

          Swal.fire('Erro!', 'Ocorreu um erro de sistema, por favor tente novamente', 'error');
          // @ts-ignore
          this.ngxLoader.stop();
        });
      }
    });
  }

  // tslint:disable-next-line:typedef
  getCardBrand(card: any) {
    try {
       EfiJs.CreditCard
          .setCardNumber(card)
          .verifyCardBrand()
          .then((brand: string) => {
            console.log('Bandeira: ', brand);
            this.stripeTest.get('cardBrand')?.setValue(brand);
            if (brand !== 'undefined') {
              // Exemplo: executar a função para gerar o payment_token com a bandeira identificada
            }
            this.brand = brand;
            console.log(this.stripeTest.getRawValue());
          }).catch((err: { code: any; error: any; error_description: any; }) => {
            this.ngxLoader.stop();
            Swal.fire('Erro!', err.error_description, 'error');
            console.log('Código: ', err.code);
            console.log('Nome: ', err.error);
            console.log('Mensagem: ', err.error_description);
      });
    } catch (error) {
      console.log('Código: ', error.code);
      console.log('Nome: ', error.error);
      console.log('Mensagem: ', error.error_description);
      this.ngxLoader.stop();
      Swal.fire('Erro!', error.error_description, 'error');
    }


  }

  // tslint:disable-next-line:variable-name typedef
  tokenCard(brand: any, number: any, cvv: any, expMonth: any, expYear: any) {
    try {
      EfiJs.CreditCard
          .setAccount(environment.efiAccount)
          .setEnvironment(environment.efiStage) // 'production' or 'sandbox'
          .setCreditCardData({
            brand,
            number,
            cvv,
            expirationMonth: expMonth,
            expirationYear: expYear,
            reuse: true
          })
          .getPaymentToken()
          .then((data: { payment_token: any; card_mask: any; }) => {
            this.stripeTest.get('cardToken')?.setValue(data.payment_token);
            this.stripeTest.get('creditCard')?.setValue(data.card_mask);
            const payment_token = data.payment_token;
            const card_mask = data.card_mask;
            console.log('payment_token', payment_token);
            console.log('card_mask', card_mask);
            this.ngxLoader.stop()
            this.sendForm();
          }).catch((err: { code: any; error: any; error_description: any; }) => {
        console.log('Código: ', err.code);
        console.log('Nome: ', err.error);
        console.log('Mensagem: ', err.error_description);
        this.ngxLoader.stop();
        Swal.fire('Erro!', err.error_description, 'error');
      });
    } catch (error) {
      console.log('Código: ', error.code);
      console.log('Nome: ', error.error);
      console.log('Mensagem: ', error.error_description);
      this.ngxLoader.stop();
      Swal.fire('Erro!', error.error_description, 'error');
    }
  }

  // @ ts-ignore
  getParcelamento(){
    try {
      EfiJs.CreditCard
          .setAccount('Identificador_de_conta_aqui')
          .setEnvironment('production') // 'production' or 'sandbox'
          .setBrand('visa')
          .setTotal(28990)
          .getInstallments()
          .then((installments: any) => {
            console.log('Parcelas', installments);
          }).catch((err: { code: any; error: any; error_description: any; }) => {
        console.log('Código: ', err.code);
        console.log('Nome: ', err.error);
        console.log('Mensagem: ', err.error_description);
      });
    } catch (error) {
      console.log('Código: ', error.code);
      console.log('Nome: ', error.error);
      console.log('Mensagem: ', error.error_description);
    }
  }


  // tslint:disable-next-line:typedef
  getEstados() {
    this.cepService.getEstados().subscribe((result: any) => {
      console.log(result);
      this.estados = result.estados;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      // @ts-ignore
      this.ngxLoader.stop();
    }, err => {
      console.log("erro", err);
      this.ngxLoader.stop();
    }, () => {
      // @ts-ignore
      this.ngxLoader.stop();
    });
  }


  // tslint:disable-next-line:typedef
  findAddress(){
    // @ts-ignore
    this.ngxLoader.start();
    this.cepService.getAddress(this.stripeTest.get('zipcode')?.value).subscribe((result: any) => {
      // tslint:disable-next-line:no-non-null-assertion
      this.stripeTest.get('address')!.setValue(result.logradouro);
      // tslint:disable-next-line:no-non-null-assertion
      this.stripeTest.get('neigthborhood')!.setValue(result.bairro);
      // tslint:disable-next-line:no-non-null-assertion
      this.stripeTest.get('city')!.setValue(result.cidade);
      // tslint:disable-next-line:no-non-null-assertion
      this.stripeTest.get('county')!.setValue(result.uf);
      console.log(result);
      this.cdr.detectChanges();
      this.cdr.markForCheck();
      // @ts-ignore
      this.ngxLoader.stop();
    }, err => {
      // @ts-ignore
      this.ngxLoader.stop();
    }, () => {
      this.ngxLoader.stop();
    } );
  }
}
