import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { BlogHelperService } from 'src/app/components/helper/blog/blog-helper.service';
import {SiteService} from '../../../../services/site.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  lastNews: any = [];

  constructor(private siteService: SiteService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.siteService.getLastNews().subscribe((result: any) => {
      this.lastNews = result;
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
  }

}
