<!-- Progress bar Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <img src="assets/img/progress-bar/1.jpg" alt="img">
            </div>
            <div class="col-lg-4 p-3 p-lg-0">
                <div class="sigma_box sigma_box-absolute sigma_box-lg m-0 sigma_box-progress">
                    <i class="flaticon-sun"></i>
                    <div class="sigma_round-button mb-4">
                        <span> <b class="counter" [countUp]="75">0</b> <span class="custom-primary">%</span> </span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 197 197" enable-background="new 0 0 197 197" xml:space="preserve">
                            <circle class="sigma_round-button-stroke" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                            <circle class="sigma_progress-round sigma_round-button-circle" [style.stroke-dashoffset.px]="290" stroke-linecap="round" cx="98.5" cy="98.6" r="97.5"></circle>
                        </svg>
                    </div>
                    <h6>Donation Success Rate</h6>
                    <p class="m-0 blockquote bg-transparent"> Design better websites and spend less time without restricting </p>
                </div>
            </div>
            <div class="col-lg-4 z-index-3">
                <img src="assets/img/progress-bar/2.jpg" alt="img">
            </div>
        </div>
    </div>
</div>
<!-- Progress bar End -->