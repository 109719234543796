<!-- CTA Start -->
<div class="section mt-negative-md p-0 d-none d-lg-block">
    <div class="container">
        <div class="p-5 bg-cover d-block d-md-flex align-items-center justify-content-between"
            [style.background-image]="'url(assets/img/calltoaction.jpg)'">
            <div>
                <h5 class="text-white mb-2">Precisamos de Você!</h5>
                <p class="text-white mb-0"> Dê o próximo passo na sua jornada maçônica e junte-se aos Shriners hoje mesmo </p>
            </div>
            <a href="https://www.seramal.com.br" target="_blank" class="mt-3 mt-md-0 sigma_btn-custom text-white light">Seja um Shriner</a>
        </div>
    </div>
</div>
<!-- CTA End -->
