import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DonationRoutingModule } from './donation-routing.module';
import { DonationComponent } from './donation.component';
import { SharedModule } from '../../shared/shared.module';
import { ContentComponent } from './content/content.component';
import { ServiceBlockComponent } from './service-block/service-block.component';
import { CampainComponent } from './campain/campain.component';
import {NgxMaskModule} from 'ngx-mask';
import {CurrencyMaskModule} from 'ngx-currency-mask';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {NgPaymentCardModule} from 'ng-payment-card';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {ClipboardModule} from '@angular/cdk/clipboard';



@NgModule({
    declarations: [
        DonationComponent,
        ContentComponent,
        ServiceBlockComponent,
        CampainComponent
    ],
    exports: [
        ServiceBlockComponent
    ],
    imports: [
        CommonModule,
        DonationRoutingModule,
        SharedModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        CurrencyMaskModule,
        CreditCardDirectivesModule,
        NgPaymentCardModule,
        NgxUiLoaderModule,
        ClipboardModule,

    ]
})
export class DonationModule { }
