<section class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 d-none d-lg-block">
                <div class="sigma_counter bg-cover primary-overlay bg-norepeat bg-center" style="background-image: url(assets/img/counter.jpg)">
                    <h4> <b class="counter" [countUp]="25">0</b> <span>+</span> </h4>
                    <p>Join Our Church</p>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="me-lg-30">
                    <div class="section-title mb-0 text-start">
                        <p class="subtitle">We Have 25+ Years of Experience</p>
                        <h4 class="title">We Are A Church That Believes In God.</h4>
                    </div>
                    <p class="blockquote bg-transparent"> We are a church that belives in Jesus christ and the followers and We are a church that belives in Jesus christ </p>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="sigma_icon-block icon-block-3">
                                <div class="icon-wrapper">
                                    <i class="flaticon-church-2"></i>
                                </div>
                                <div class="sigma_icon-block-content">
                                    <h5> Helping Hand </h5>
                                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sigma_icon-block icon-block-3">
                                <div class="icon-wrapper">
                                    <i class="flaticon-charity"></i>
                                </div>
                                <div class="sigma_icon-block-content">
                                    <h5> Open Hearts </h5>
                                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a routerLink="/about" class="sigma_btn-custom light">Learn More <i class="far fa-arrow-right"></i> </a>
                </div>
            </div>
        </div>
    </div>
</section>