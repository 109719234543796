import { Component, Injectable } from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslationWidth} from '@angular/common';

const I18N_VALUES = {
    'pt-br': {
        weekdays: ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'],
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
};

@Injectable()
// @ts-ignore
export class I18n {
    language = 'pt-br';
}


@Injectable()
// @ts-ignore
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    // tslint:disable-next-line:variable-name
    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        // @ts-ignore
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        // @ts-ignore
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return "";
    }

    getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this.getWeekdayShortName(weekday);
    }
}
