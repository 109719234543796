<app-header-two></app-header-two>
<app-banner></app-banner>
<app-about-text></app-about-text>
<app-services></app-services>
<app-page-cta></app-page-cta>
<app-success-rate></app-success-rate>
<!-- volunteers Start -->
<section class="section section-padding light-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-30">
                <div class="section-title mb-0 text-start">
                    <p class="subtitle">volunteers</p>
                    <h4 class="title">Our Volunteers Achievments</h4>
                </div>
                <p class="blockquote bg-transparent"> We are a church that belives in Jesus christ and the followers and
                    We are a church that belives in Jesus christ </p>
                <app-short-testimonials></app-short-testimonials>
            </div>
            <div class="col-lg-6">
                <div class="me-lg-30">
                    <app-volunteers></app-volunteers>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- volunteers End -->
<app-ministry></app-ministry>
<app-testimonials></app-testimonials>
<app-quote-cta></app-quote-cta>
<app-blogs></app-blogs>
<footer class="sigma_footer footer-2">
    <app-footer></app-footer>
</footer>