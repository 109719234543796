<app-header-three></app-header-three>
<app-banner></app-banner>
<app-next-event></app-next-event>
<app-donation></app-donation>
<app-about-text></app-about-text>
<app-page-cta-home-3></app-page-cta-home-3>


<app-faqs></app-faqs>

<app-service-block></app-service-block>


<app-blogs></app-blogs>
<div class="section mb-negative p-0">
    <app-features></app-features>
</div>
<footer class="sigma_footer footer-2 bg-cover bg-center bg-transparent light-overlay"
        [style.background-image]="'url(assets/img/fundo_footer.jpg)'">
    <app-footer></app-footer>
</footer>