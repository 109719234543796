import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.css']
})
export class HomeThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
