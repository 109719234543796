<!-- FAQ Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 d-none d-lg-block">
                <img src="assets/img/justFEZ.png" class="w-100" alt="about">
            </div>
            <div class="col-lg-6">
                <div class="me-lg-30">
                    <div class="section-title section-title-2 text-start">
                        <p class="subtitle">FAQ</p>
                        <h4 class="title">Tire suas Dúvidas</h4>
                        <p> Aqui respondemos as perguntas mais frequentes </p>
                    </div>
                    <ngb-accordion [closeOthers]="true" activeIds="static-1" class="with-gap">
                        <ngb-panel id="static-{{1+i}}" *ngFor="let item of faqs | slice:0:3;let i = index">
                            <ng-template ngbPanelTitle>
                                {{item.title}}
                            </ng-template>
                            <ng-template ngbPanelContent>
                                {{item.text}}
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- FAQ End -->