import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(private http: HttpClient) { }

  getEstados() {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/helpers/correios/estados`, { headers: httpHeaders });
  }

  getAddress(cep:any) {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.api}/helpers/correios/cep/${cep}`, { headers: httpHeaders });
  }
}
