import { Component } from '@angular/core';
import { HelperService } from '../../helper/helper.service';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent extends HelperService {

}
