<!-- partial:partia/__subheader.html -->
<breadcrumb #parent>
    <div class="header-absolute"></div>
    <div class="sigma_subheader dark-overlay dark-overlay-2" [style.background-image]="'url(' + image + ')'">
        <div class="container">
            <div class="sigma_subheader-inner">
                <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                    <div class="sigma_subheader-text">
                        <h1>{{ route.displayName }}</h1>
                        <br *ngIf="text">
                        <p class="blockquote"  *ngIf="text"> {{text}} </p>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink="/" class="btn-link">Início</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ route.displayName }}</li>
                        </ol>
                    </nav>
                </ng-template>
            </div>
        </div>
    </div>
</breadcrumb>
<!-- partial -->
