<ngx-ui-loader></ngx-ui-loader>
<div class="section">
    <div class="container">
        <div class="section-title text-center">
            <p class="subtitle">Ajude a nossas crianças se tornarndo um amigo do Amal</p>
            <h4 class="title">Faça a sua doação única ou mensal.</h4>
        </div>
        <form  [formGroup]="stripeTest">
            <div class="row mb-5">
                <div class="col-md-8">
                    <h5>Seus Dados</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="text" class="form-control" placeholder="Nome Completo" name="name" formControlName="name">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('name').invalid">Informe o nome completo</small>
                        </div>
                        <div class="col-md-6">
                            <input type="text" class="form-control" placeholder="CPF/CNPJ" name="taxNumber"  formControlName="taxNumber" [mask]="'000.000.000-00||00.000.000/0000-00'">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('taxNumber').invalid">Informe o CPF ou CNPJ</small>
                        </div>

                    </div><br>
                    <div class="row mb-5">
                        <div class="col-md-4">
                            <input type="email" placeholder="Email" name="email" class="form-control" formControlName="email">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('email').invalid">Informe o Email</small>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" placeholder="Telefone" name="mobile"  formControlName="mobile" [mask]="'(00) 00000-0000'">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('mobile').invalid">Informe o Telefone Celular</small>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" placeholder="Data de Nascimento (DD/MM/AAAA)" name="nascimento"  formControlName="nascimento" [mask]="'00/00/0000'">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('nascimento').invalid">Informe a data de nascimento</small>
                        </div>

                    </div>
                    <div class="row mb-5">
                        <div class="col-md-2">
                            <input type="text" placeholder="CEP" name="zipcode" class="form-control" formControlName="zipcode" [mask]="'00000-000'" (change)="findAddress()">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('zipcode').invalid">Informe o CEP</small>
                        </div>
                        <div class="col-md-5">
                            <input type="text" placeholder="Endereço" name="address" class="form-control" formControlName="address">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('address').invalid">Informe o endereço</small>
                        </div>
                        <div class="col-md-2">
                            <input type="text" placeholder="número" name="number" class="form-control" formControlName="number">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('number').invalid">Informe o numero</small>
                        </div>
                        <div class="col-md-3">
                            <input type="text" placeholder="complemento" name="complement" class="form-control" formControlName="complement">
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-5">
                            <input type="text" placeholder="Bairro" name="neigthborhood" class="form-control" formControlName="neigthborhood">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('neigthborhood').invalid">Informe o Bairro</small>
                        </div>
                        <div class="col-md-5">
                            <input type="text" placeholder="Cidade" name="city" class="form-control" formControlName="city">
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('city').invalid">Informe a cidade</small>
                        </div>

                        <div class="col-md-2">
                            <select class="form-control ms-0"  name="county" formControlName="county" id="county">
                                <option selected>Selecione</option>
                                <option *ngFor="let estado of estados" [value]="estado.estadoCode">{{estado.estadoName}}</option>
                            </select>
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('county').invalid">Informe o estado</small>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md-12">
                            <h5>Valor da Doação</h5>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button class="sigma_btn-custom shadow-none btn-sm" type="button">R$</button>
                                </div>
                                <input type="text" class="form-control ms-0" placeholder="100" name="amount" formControlName="amount" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" >
                            </div>
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('amount').invalid">O valor mínimo de doação é R$ 10,00</small>
                            <input type="checkbox" id="includeFees" formControlName="includeFees" name="includeFees" [value]="true">
                            <label for="includeFees" class="mb-0" ngbTooltip="Taxas administrativas são aquelas cobradas pelo banco para liquidar o pagamento: Boleto: R$ 4,00, Cartão: 4,6%, PIX: 1,5%">Por favor inclua as taxas administrativas na minha doação</label>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <div class="col-md-4">
                            <br><h5>Forma de Doação</h5>
                            <div class="form-group mb-5">

                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <input type="radio" id="unique" name="donationType" value="unique" formControlName="donationType">
                                        <label for="unique" class="mb-0">Única</label>
                                    </div>
                                    <div class="d-flex align-items-center ms-4">
                                        <input type="radio" id="monthly" name="donationType" value="mohtly" formControlName="donationType">
                                        <label for="monthly" class="mb-0" ngbTooltip="A doação mensal é valida por 01 ano, você receberá 12 cobranças dessa doação">Mensal</label>
                                    </div>
                                </div>
                                <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('donationType').invalid">Informe o tipo de doação</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <br><h5>Forma de Pagamento</h5>
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <input type="radio" id="boleto" name="paymentMethod" [value]="2" formControlName="paymentMethod">
                                    <label for="boleto" class="mb-0"> <i class="fa fa-barcode">&nbsp;Boleto</i> </label>
                                </div>
                                <div class="d-flex align-items-center ms-4">
                                    <input type="radio" id="cartao" name="paymentMethod" [value]="1" formControlName="paymentMethod">
                                    <label for="cartao" class="mb-0"><i class="fa fa-credit-card">&nbsp;Cartão</i> </label>
                                </div>
                                <div class="d-flex align-items-center ms-4" *ngIf="stripeTest.get('donationType').value === 'unique'">
                                    <input type="radio" id="pix" name="paymentMethod" [value]="3" formControlName="paymentMethod">
                                    <label for="pix" class="mb-0"><i class="fa fa-qrcode">&nbsp;PIX</i> </label>
                                </div>

                            </div>
                            <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('paymentMethod').invalid">Informe a forma de pagamento</small>
                        </div>

                        <div class="row mb-5">
                            <ng-container *ngIf="stripeTest.get('paymentMethod')?.value === 1">
                                <div class="col-lg-6">
                                    <input type="tel" #cardNumber class="form-control ms-0"  name="creditCard" formControlName="creditCard" placeholder="Número do Cartão de Crédito" (change)="getCardBrand(cardNumber.value)" >
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-control ms-0" name="expiryMonth" formControlName="expiryMonth">
                                        <option [value]="" selected="selected">Mês Vencimento</option>
                                        <option [value]="'01'">01-Janeiro</option>
                                        <option [value]="'02'">02-Fevereiro</option>
                                        <option [value]="'03'">03-Março</option>
                                        <option [value]="'04'">04-Abril</option>
                                        <option [value]="'05'">05-Maio</option>
                                        <option [value]="'06'">06-Junho</option>
                                        <option [value]="'07'">07-Julho</option>
                                        <option [value]="'08'">08-Agosto</option>
                                        <option [value]="'09'">09-Setembro</option>
                                        <option [value]="'10'">10-Outubro</option>
                                        <option [value]="'11'">11-Novembro</option>
                                        <option [value]="'12'">12-Dezembro</option>
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-control ms-0" name="expiryYear" formControlName="expiryYear">
                                        <option [value]="" selected="selected">Ano Vencimento</option>
                                        <option [value]="2023">2023</option>
                                        <option [value]="2024">2024</option>
                                        <option [value]="2025">2025</option>
                                        <option [value]="2026">2026</option>
                                        <option [value]="2027">2027</option>
                                        <option [value]="2028">2028</option>
                                        <option [value]="2029">2029</option>
                                        <option [value]="2030">2030</option>
                                        <option [value]="2031">2031</option>
                                        <option [value]="2032">2032</option>
                                        <option [value]="2033">2033</option>

                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <input type="tel" class="form-control ms-0"  name="cvv" formControlName="cvv" placeholder="CVV" >
                                </div>
                            </ng-container>

                            <div class="col-md-12" *ngIf="stripeTest.get('paymentMethod')?.value === 2">
                                <h4>Clique em doar agora para gerar o seu boleto</h4>
                            </div>
                            <div class="col-md-12" *ngIf="stripeTest.get('paymentMethod')?.value === 3">
                                <h4>Clique em doar agora para gerar o seu QRCode</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <h5>Mensagem</h5>
                                    <textarea name="message" class="form-control" placeholder="Você pode enviar uma mensagem para nós junto com a sua doação" rows="6"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="checkbox" id="acceptTerms" formControlName="acceptTerms" name="acceptTerms" [value]="true">
                                <label for="acceptTerms" class="mb-0">Aceito os termos de uso e politica de privacidade do Amal Shriners</label>
                                <small class="error-texts" *ngIf="stripeTest.invalid && stripeTest.get('acceptTerms').invalid">Aceite os Termos</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="checkbox" id="anonimous" name="anonimous" formControlName="anonymous" [value]="true">
                                <label for="anonimous" class="mb-0">Gostaria de permanecer anônimo nos informes e relatórios do Amal sobre esta doação.</label>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-lg-12">
                                <input type="checkbox" id="communications" name="communications" formControlName="communications" [value]="true">
                                <label for="communications" class="mb-0">Aceito receber comunicados do Amal Shriners.</label>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-lg-12 text-center">
                                <button type="submit" class="sigma_btn-custom" name="button" (click)="save()"> Doar Agora </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="sidebar-widget widget-categories">
                        <h5 class="widget-title"> Perguntas Frequentes </h5>
                        <ngb-accordion [closeOthers]="true"  class="with-gap">
                            <ngb-panel id="static-1">
                                <ng-template ngbPanelTitle>
                                    Minha Doação é Segura?
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    Absolutamente. A segurança e a confidencialidade das suas informações são a nossa maior prioridade. Usamos a tecnologia SSL (secure socket layer) padrão da indústria para proteger suas informações e fornecer um ambiente seguro para doações online. Não venderemos, comercializaremos ou compartilharemos suas informações pessoais com ninguém, nem enviaremos correspondências de doadores em nome de outras organizações, e só compartilharemos informações pessoais se você nos tiver dado permissão específica para fazê-lo.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2">
                                <ng-template ngbPanelTitle>
                                    Você emitem um recibo?
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    Um recibo de doação será enviado para o endereço de e-mail fornecido neste formulário. Certifique-se de guardar uma cópia do recibo para fins fiscais. Se você selecionar uma doação recorrente, um recibo será enviado mensalmente quando a doação for processada.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3">
                                <ng-template ngbPanelTitle>
                                    Posso deduzir do Imposto de Renda?
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    Sim.Amal Shriners é uma organização totalmente qualificada. As contribuições para Amal Shrinres são dedutíveis de impostos na medida permitida por lei. Para reivindicar uma doação como dedução dos impostos, guarde o recibo da doação por e-mail como seu registro oficial. Os recibos são emitidos por e-mail após a conclusão bem-sucedida de uma doação.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4">
                                <ng-template ngbPanelTitle>
                                    Como o Amal utiliza esses recursos?
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    No Amal Shriners, a administração é um dos nossos valores fundamentais. Aproximadamente 85 por cento de todos os fundos gastos anualmente pelo Amal são dedicados ao atendimento de pacientes, pesquisa e educação. Trabalhamos duro para garantir que suas doações sejam usados da maneira mais eficiente e eficaz.
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                    <div class="sidebar-widget widget-categories">
                        <h5 class="widget-title"> Ultimas Doações </h5>
                        <ul class="sidebar-widget-list">
                            <!-- Data -->
                            <li>
                                Anônimo - <small>R$ 100.00</small>
                            </li>
                            <li>
                                João - <small>R$ 50.00</small>
                            </li>
                            <li>
                                Marta - <small>R$ 30.00</small>
                            </li>
                            <li>
                                Mario - <small>R$ 3000.00</small>
                            </li>
                            <!-- Data -->
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Form nEnd -->


<ng-template #modalPix let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Finalize Sua Doação:</h4>
        <button type="button" class="btn btn-info">
            <span aria-hidden="true" (click)="d('Cross click')">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <strong>Use o QRCode abaixo no aplicativo do seu banco ou copie e texto do PIX</strong>
        <div class="row mb-5">
            <div class="col-lg-12 center">
                <img [attr.src]="qrCode">
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-12">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="sigma_btn-custom shadow-none btn-sm" type="button" [cdkCopyToClipboard]="qrCodeText"><i class="fa fa-copy"> </i></button>
                    </div>
                    <input type="text" class="form-control ms-0" value="{{qrCodeText}}">
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">

    </div>
</ng-template>


<ng-template #modalBoleto let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Finalize Sua Doação:</h4>
        <button type="button" class="btn btn-info">
            <span aria-hidden="true" (click)="d('Cross click')">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <strong>Use o QRCode abaixo no aplicativo do seu banco ou pague o boleto</strong>
        <div class="row md-5">
            <div class="col-lg-6 center">
                <img [attr.src]="qrCode" width="30%">
            </div>
            <div class="col-lg-6 center">
                <a href="{{linkBoleto}}" target="_blank" ngbTooltip="Clique para baixar o boleto"> <i class="fa fa-download fs-2"></i> </a>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="sigma_btn-custom shadow-none btn-sm" type="button" [cdkCopyToClipboard]="linhaDigitavel"><i class="fa fa-copy"> </i></button>
                    </div>
                    <input type="text" class="form-control ms-0" id="linhadigitavel" value="{{linhaDigitavel}}">
                </div>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-12">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <button class="sigma_btn-custom shadow-none btn-sm" type="button" [cdkCopyToClipboard]="qrCodeText"><i class="fa fa-copy"> </i></button>
                    </div>
                    <input type="text" class="form-control ms-0" value="{{qrCodeText}}">
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">

    </div>
</ng-template>


<ng-template #modalCartaoApproved let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Obrigado pela sua doação:</h4>
        <button type="button" class="btn btn-info">
            <span aria-hidden="true" (click)="d('Cross click')">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <strong>O Amal Shriners Agradece a sua doação, recibos e novas informações serão eviadas no seu email informado</strong>
    </div>
</ng-template>

<ng-template #modalCartaoWaiting let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Doação em analise:</h4>
        <button type="button" class="btn btn-info">
            <span aria-hidden="true" (click)="d('Cross click')">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <strong>Obrigado pela sua doação, esta transação está passando por uma analise anti-fraude, em breve você receberá uma confirmação no email informado</strong>
    </div>
</ng-template>


