<!-- Middle Footer -->
<div class="sigma_footer-middle">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-widget">
                <h5 class="widget-title">Sobre o Amal</h5>
                <p class="mb-4">O Shriners International é uma fraternidade baseada em diversão, companheirismo e nos princípios maçônicos de amor fraterno, auxílio e verdade com cerca de 375.000 membros em 198 templos</p>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center">
                    <i class="far fa-phone custom-primary me-3"></i>
                    <span>+55 11 95134-0724</span>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                    <i class="far fa-envelope custom-primary me-3"></i>
                    <span>contato@amalshriners.org</span>
                </div>
                <div class="d-flex align-items-center justify-content-md-start justify-content-center mt-2">
                    <i class="far fa-map-marker custom-primary me-3"></i>
                    <span>R. Luigi Galvani, 42, Cj 11/12 - São Paulo - SP</span>
                </div>
            </div>
            <!-- Data -->
            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget" *ngFor="let data of links">
                <h5 class="widget-title">{{data.title}}</h5>
                <ul>
                    <!-- Data -->
                    <li *ngFor="let item of data.items"> 
                        <a routerLink="{{item.link}}" *ngIf="!item.external">{{item.title}}</a>
                        <a href="{{item.link}}" *ngIf="item.external" target="_blank">{{item.title}}</a>
                    </li>
                    <!-- Data -->
                </ul>
            </div>
            <!-- Data -->
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 d-none d-lg-block footer-widget widget-recent-posts">
                <h5 class="widget-title">Últimas Noticias</h5>
                <!-- Data -->
                <article class="sigma_recent-post" *ngFor="let item of lastNews">
                    <a routerLink="/blog-details/{{item.uuid}}">
                        <img [src]="item.headerImage" [alt]="item.title">
                    </a>
                    <div class="sigma_recent-post-body">
                        <a routerLink="/blog-details/{{item.uuid}}"> <i class="far fa-calendar"></i> {{item.publishDate | date : 'dd/MM/yyyy'}}</a>
                        <h6> <a routerLink="/blog-details/{{item.uuid}}">{{item.title}}</a> </h6>
                    </div>
                </article>
                <!-- Data -->
            </div>
        </div>
    </div>
</div>
<!-- Footer Bottom -->
<div class="sigma_footer-bottom">
    <div class="container-fluid">
        <div class="sigma_footer-copyright">
            <p>© AMAL SHRINERS - CNPJ: 13.411.722/0001-80</p>
        </div>
        <div class="sigma_footer-logo">
            <img src="assets/img/amal-logo.png" alt="logo" style="height:100px;">
        </div>
        <ul class="sigma_sm square">
            <li>
                <a href="#">
                    <i class="fab fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fab fa-twitter"></i>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fab fa-youtube"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
