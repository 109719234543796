<!-- Banner Start -->
<div class="sigma_banner banner-3">
    <ngx-slick-carousel [config]="settings" class="sigma_banner-slider">
        <!-- Banner Item Start -->
        <div class="light-bg sigma_banner-slider-inner bg-cover bg-center bg-norepeat" ngxSlickItem [style.background-image]="'url('+item.image+')'" *ngFor="let item of banner">
            <div class="sigma_banner-text">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h1 class="title">{{item.title}}</h1>
                            <p class="blockquote mb-0 bg-transparent">{{item.text}}</p>
                            <div class="section-button d-flex align-items-center">
                                <a routerLink="/contact" class="sigma_btn-custom">Join Today <i class="far fa-arrow-right"></i> </a>
                                <a routerLink="/services" class="ms-3 sigma_btn-custom white">View Services <i class="far fa-arrow-right"></i> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner Item End -->
    </ngx-slick-carousel>
</div>
<!-- Banner End -->