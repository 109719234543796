<!-- Blog Start -->
<div class="section section-padding pt-0" *ngIf="lastNews.length > 0">
    <div class="container">
        <div class="section-title section-title-2 text-center">
            <p class="subtitle">Amal</p>
            <h4 class="title">Últimas Notícias do Amal</h4>
        </div>
        <div class="row">
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6" *ngFor="let item of lastNews">
                <article class="sigma_post style-2">
                    <div class="sigma_post-thumb">
                        <a routerLink="/blog-details/{{item.uuid}}">
                            <img [src]="item.headerImage" [alt]="item.title">
                        </a>
                    </div>
                    <div class="sigma_post-body">
                        <div class="sigma_post-meta">
                            <a routerLink="/blog-details/{{item.uuid}}" class="sigma_post-date"> <i class="far fa-calendar"></i> {{item.publishDate | date : 'dd/MM/yyyy'}}</a>
                        </div>
                        <h5> <a routerLink="/blog-details/{{item.uuid}}">{{item.title}}</a> </h5>
                    </div>
                </article>
            </div>
            <!-- Article End -->
        </div>
    </div>
</div>
<!-- Blog End -->
