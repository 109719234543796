<!-- Blog Start -->
<div class="section section-padding">
    <div class="container">
        <div class="section-title text-center">
            <p class="subtitle">Blog</p>
            <h4 class="title">News Feed</h4>
        </div>
        <div class="row">
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6" *ngFor="let item of getRecentPost()">
                <article class="sigma_post">
                    <div class="sigma_post-thumb">
                        <a routerLink="/blog-details/{{item.id}}">
                            <img [src]="item.image[0]" [alt]="item.title">
                        </a>
                    </div>
                    <div class="sigma_post-body">
                        <div class="sigma_post-meta">
                            <div class="me-3">
                                <i class="far fa-cross"></i>
                                <!-- Data -->
                                <a routerLink="/blog/tag/{{tag.id}}" class="sigma_post-category" *ngFor="let tag of getTags(item.tags) | slice:0:2">{{tag.title}}</a>
                                <!-- Data -->
                            </div>
                            <a routerLink="/blog-details/{{item.id}}" class="sigma_post-date"> <i class="far fa-calendar"></i>{{item.postdate}}</a>
                        </div>
                        <h5> <a routerLink="/blog-details/{{item.id}}">{{item.title}}</a> </h5>
                        <div class="sigma_post-single-author" *ngFor="let author of getAuthor(item.author)">
                            <img [src]="author.image" [alt]="author.name">
                            <div class="sigma_post-single-author-content">
                                By <p>{{author.name}}</p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <!-- Article End -->
        </div>
    </div>
    <div class="spacer spacer-bottom spacer-lg light-bg pattern-triangles"></div>
</div>
<!-- Blog End -->